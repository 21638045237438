<template>
  <section>
    <form
      name="paymentForm"
      ref="paymentForm"
      id="payment-form"
      method="GET"
      :action="actionUrl"
      target="_blank"
    ></form>
  </section>
</template>

<script>
import { checkPaymentRoutesName } from "@/router/names";
export default {
  name: "FormMonei",
  props: { formData: { type: Object, required: true } },
  mounted() {
    this.$refs.paymentForm.submit();
    this.$router.replace({
      name: checkPaymentRoutesName.INDEX.name,
      params: { requestId: this.formData.request_id, orderStatus: "prepago" },
    });
  },
  computed: {
    actionUrl() {
      return `${process.env.VUE_APP_MONEI_URL}/${this.formData.payment_id}`;
    },
  },
};
</script>
